"use strict";

var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;

if (isSafari) {
    $('html').addClass('safari');
}

function bindItemTiles(elements) {
    /*
    $(elements).click(function () {

        if ($(this).css('opacity') > 0) {

            var linkSelector = $(this).data('click-target');
            var links = $(this).find(linkSelector);

            if (links.length > 0) {
                links[0].click();
            }
        }

    });*/

    $(elements).each(function () {

        var linkSelector = $(this).data('click-target');
        var links = $(this).find(linkSelector);

        if (links.length > 0) {
            $(this).data('href', $(links[0]).attr('href'));
            $(links[0]).removeAttr('href');
        }

        $(this).click(function () {
            if ($(this).css('opacity') > 0) {
                window.location = $(this).data('href');
            }
        });


    });

}
bindItemTiles($('div[data-click-target]'));

/*
$('.content-item.project').each(function () {
    var firstImage = $(this).find('.media-items').children().first();
    firstImage.prependTo($(this).find('header'));
});
*/

$('[data-load-target]').click(function () {

    var button = $(this);

    $(button).attr('disabled','true');

    var itemList = $(this).parent().prev();
    var itemTotal = $(button).data('totalItems');
    var tagetId = $(button).data('loadTarget');

    var page = $(button).data('page');
    var pagerId = $(button).data('pagerId');
    var pageSize = $(button).data('pageSize');
    var pageTerm = $(button).data('term');

    if (button.data('clearItems') === true) {
        page = 1;
    }

    $(this).attr('diabled', 'diabled');

    var url = "/buzz.interactive/partial/index/" + tagetId + "?" + pagerId + "=" + page;

    if (pageTerm && pageTerm !== '') {
        url += "&termid=" + pageTerm;
    }

    $.ajax({
        url: url,
    }).done(function (data) {

        var content = $(data);
        var elements = $(content).find('.list-tiles li');

        if (button.data('clearItems') === true) {
            $(button).fadeIn();
        }

        elements.appendTo(itemList);


        bindItemTiles(elements.find('div[data-click-target]'));

        $(button).data('page', page + 1);
        $(button).removeAttr('disabled');

        if (itemList.children().length === itemTotal) {
            $(button).fadeOut();
        }
        else if (itemList.children().length < itemTotal) {
            $(button).fadeIn();
        }
    });
});
