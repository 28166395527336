"use strict";

$('a[href=#about]').click(function () {
    $('a[href=#about]').parent().toggleClass('current');
    $('#layout-wrapper').toggleClass('aside-reveal');
    return false;
});

var currentAside = '';
var asideIsRevealed = false;


$('a[href*=#aside-]').click(function () {

    var target = $(this).attr('href');

    if (currentAside === target) {

        $('a[href=' + target + ']').parent().removeClass('current');

        $('#layout-wrapper').removeClass('aside-reveal');
        asideIsRevealed = false;
      //  $('#layout-aside-reveal article').removeClass('active');
        currentAside = '';

    } else {

        currentAside = target;
        $('a[href*=#aside-]').parent().removeClass('current');
        $('a[href=' + target + ']').parent().addClass('current');

        $('#layout-aside-reveal article').removeClass('active');
        $(target).addClass('active');
        $('#layout-wrapper').addClass('aside-reveal');
        asideIsRevealed = true;
    }

    if (asideIsRevealed) {
        setTimeout(function () {
            $('#layout-aside-reveal').addClass('animate');
        }, 500);
    } else {
        $('#layout-aside-reveal').removeClass('animate');
    }


    return false;
});